import { Fragment, useState, useEffect, lazy } from 'react'
import { Outlet } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline'

import Login from "./pages/Login.js"
import Maintenance from "./pages/Maintenance.js"

import Spinner from "./components/spinner.js"
import Error from "./components/error.js"
import MainNavigation from "./components/mainnavigation.js"
import UserNavigation from "./components/usernavigation.js"
import LanguageNavigation from "./components/languagenavigation.js"
import SystemAlert from "./components/systemalert.js"
import ColorToggle from './components/colortoggle.js'

import { UserContext } from "./context/user.js"
import { AlertContext } from "./context/alert.js"
import { LanguageContext } from "./context/language.js"
import { ErrorProvider } from "./context/error.js"
import { getLanguage } from './utils/index.js'
import { localizeText } from './lang/index.js'
import { getKyc, fetchBbApi } from "./utils/bullbitcoin.js"

const Onboarding = lazy(() => import("./components/onboarding.js"));

export default function Page() {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [alertData, setAlertData] = useState(null)
  const [language, setLanguage] = useState(getLanguage())
  const [isMaintenance, setIsMaintenance] = useState(false)

  const handleLogin = async () => {
    const response = await fetchBbApi({
      service: "api-users",
      method: "getMyUser",
      params: {
        includes: [
          "kycs",
          "groups",
        ]
      },
    })

    setLoading(false)
    getAlertData()

    if(response.error) {
      if(response.data?.status === 503) {
        setIsMaintenance(true)
      }

      return
    }

    setUser(response.element)
  }

  const getAlertData = async () => {
    const response = await fetch(`/alert`)

    if(!response || response.status !== 200) {
      return
    }

    const data = await response.json()

    if(data.error) {
      return
    }

    setAlertData(data.data)
  }

  useEffect(() => {
    handleLogin()

    const intervalId = setInterval(getAlertData, 1000 * 60)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const email = getKyc(user, 'EMAIL', 'email')
  const firstName = getKyc(user, 'PROFILE', 'firstName')
  const lastName = getKyc(user, 'PROFILE', 'lastName')

  const localized = {
    ...localizeText(language), 
    lang: language,
  }

  return (
    <UserContext.Provider value={{data: user, refresh: handleLogin}}>
      <LanguageContext.Provider value={localized}>
        <ErrorProvider>
          <AlertContext.Provider value={{data: alertData, refresh: getAlertData}}>
            <div className="bg-white h-screen">
              <div className="bg-black dark:bg-white pb-32">
                <Disclosure as="nav" className="border-b border-white border-opacity-25 bg-black-400 lg:border-none nightwind-prevent nightwind-prevent-block">
                  {({ open, close }) => (
                    <>
                      <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                          <div className="flex items-center px-2 lg:px-0">
                            <div className="flex-shrink-0">
                              <a href="#">
                                <picture>
                                  <source srcset="/bb-logo.webp" type="image/webp" />
                                  <img
                                    className="block"
                                    src="/bull-bitcoin-banner-logo.png"
                                    alt="Bull Bitcoin"
                                    width={200}
                                    height={50}
                                  />
                                </picture>
                              </a>
                            </div>
                            <div className="hidden lg:ml-10 lg:block">
                              <div className="flex space-x-4">
                                <MainNavigation language={localized} />
                              </div>
                            </div>
                          </div>
                          <div className="flex lg:hidden">
                            {/* Mobile menu button */}
                            <ColorToggle />
                            <LanguageNavigation setLanguage={setLanguage} language={language} isMobile={true} />
                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-red-700 p-2 text-red-200 hover:bg-red-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600">
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Open main menu</span>
                              {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                              ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                              )}
                            </Disclosure.Button>
                          </div>
                          <div className="hidden lg:ml-4 lg:block">
                            <div className="flex items-center">
                              {/* Profile dropdown */}
                              <ColorToggle />
                              <LanguageNavigation setLanguage={setLanguage} language={language} />
                              <Menu as="div" className="relative ml-3 flex-shrink-0">
                                <div>
                                  <Menu.Button className="relative flex rounded-full text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600">
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">Open user menu</span>
                                    <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <UserNavigation language={localized} />
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Disclosure.Panel className="lg:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                          <MainNavigation language={localized} isMobile={true} close={close} />
                        </div>
                        <div className="border-t border-red-700 pb-3 pt-4">
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              <UserCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <div className="text-base font-medium text-white">{firstName} {lastName}</div>
                              <div className="text-sm font-medium text-white">{email}</div>
                            </div>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            <UserNavigation language={localized} isMobile={true} close={close} />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <header className="py-5 sm:py-10">
                  <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    {alertData && alertData.alert && alertData.alert.active &&
                      <SystemAlert />
                    }
                  </div>
                  <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    {user &&
                      <Onboarding language={localized} />
                    }
                  </div>
                </header>
              </div>

              <main className="dark:bg-white -mt-32">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                  <div className="rounded-lg bg-white px-2 py-2 shadow sm:px-6" style={{minHeight: 400}}>
                    {loading && 
                      <Spinner container text={localized.loading} />
                    }
                    {!loading && user && <Outlet />}
                    {!loading && !user && !isMaintenance && <Login language={localized} />}
                    {!loading && !user && isMaintenance && <Maintenance language={localized} />}
                  </div>
                </div>
              </main>
            </div>
            <Error />
          </AlertContext.Provider>
        </ErrorProvider>
      </LanguageContext.Provider>
    </UserContext.Provider>
  )
}
